<template>
  <div v-if="false">TEST JODER</div>
  <v-dialog v-else v-model="dialog" width="400">
    <v-card>
      <v-card-title>
        {{ $t("configurate", { name: $tc("studio") }) }}
      </v-card-title>
      <v-card-subtitle
        v-if="studio.user.first_name"
        class="mt-0 text-center primary--text"
      >
        {{ studio.studio_name }}
      </v-card-subtitle>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row>
          <v-col cols="6"> Vacaciones </v-col>
          <v-col cols="6">
            <CloseDaysTattooer :studio="item"></CloseDaysTattooer>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6"> Servicios Propios </v-col>
          <v-col cols="6">
            <OwnService
              :tattooer_id="ts.tattooer_id"
              :studio_id="ts.studio_id"
              :value="ts.from_tattooer"
              _key="from_tattooer"
              @update="$emit('update')"
              :disabled="ts.own_service == 0"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions></v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as moment from "moment";
export default {
  name: "Configuration",
  props: ["studio"],

  mounted() {
    this.today = moment().format("YYYY-MM-DD");
  },
  data() {
    return { dialog: false, today: null };
  },
  components: {
    CloseDaysTattooer: () =>
      import("@/components/user/settings/profile/CloseDaysTattooer"),
    OwnService: () => import("@/components/tattooer/OwnService"),
  },
  methods: {
    open() {
      console.log("open");
      this.dialog = true;
    },
  },

  computed: {
    ts() {
      let ts = this.studio.tattooer_studios.find(
        (ts) => ts.tattooer_id === this.$store.state.auth.user.tattooer.id
      );
      if (ts) return ts;
      return {};
    },
  },
};
</script>